import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Head from "../components/head"
import PageLayout from "../components/hrbfpagelayout"
import speakerimg from "../images/speaker.png"

export const query = graphql`
  query($slug: String!) {
    contentfulSessionDetails(slug: { eq: $slug }) {
      title
      slug
      timing
      details {
        details
      }
      speakers {
        name
        designation
        organisation
        image {
          file {
            url
          }
        }
      }
      schedule_details {
        schedule {
          date(formatString: "MMMM D, YYYY")
          agenda {
            events {
              displayName
              slug
              startDate(formatString: "MMMM D")
              eventEndDate: endDate(formatString: "D, YYYY")
              endDate(formatString: "MMMM D, YYYY")
              eventStartMonth: startDate(formatString: "MMMM")
              eventEndMonth: endDate(formatString: "MMMM")
              registrationUrl
              registrationCtaText
              transparentHeader
              bannerImageLeft {
                file {
                  url
                }
              }
              bannerImageRight {
                file {
                  url
                }
              }
              bannerImageLeftMobile {
                file {
                  url
                }
              }
              bannerImageRightMobile {
                file {
                  url
                }
              }
              seoMetadata {
                title
                description {
                  description
                }
              }
            }
          }
        }
      }
    }
  }
`
const AgendaSession = props => {
  let registerUrl
  if (typeof window !== "undefined") {
    if (window.location.href.includes("?")) {
      let currentLocation = window.location.href.split("?")
      if (
        props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]?.agenda[0]?.events[0]?.registrationUrl.includes(
          "?"
        )
      ) {
        registerUrl =
          props?.data?.contentfulSessionDetails?.schedule_details[0]
            ?.schedule[0]?.agenda[0]?.events[0]?.registrationUrl +
          "&" +
          currentLocation[1]
      } else {
        registerUrl =
          props?.data?.contentfulSessionDetails?.schedule_details[0]
            ?.schedule[0]?.agenda[0]?.events[0]?.registrationUrl +
          "?" +
          currentLocation[1]
      }
    } else {
      registerUrl =
        props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]
          ?.agenda[0]?.events[0]?.registrationUrl
    }
  }
  const [scrollPosition, setScrollPosition] = useState(0)
  useEffect(() => {
    const close = e => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener("scroll", close)
  })
  return (
    <PageLayout
      title={props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]?.agenda[0]?.events[0]?.displayName?.toUpperCase()}
      logo={
        props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]
          ?.agenda[0]?.events[0]?.bannerLogo
      }
      date={
        props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]
          ?.agenda[0]?.events[0]?.startDate === null
          ? props?.data?.contentfulSessionDetails?.schedule_details[0]
              ?.schedule[0]?.agenda[0]?.events[0]?.endDate
          : props?.data?.contentfulSessionDetails?.schedule_details[0]
              ?.schedule[0]?.agenda[0]?.events[0]?.eventStartMonth ===
            props?.data?.contentfulSessionDetails?.schedule_details[0]
              ?.schedule[0]?.agenda[0]?.events[0]?.eventEndMonth
          ? `${props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]?.agenda[0]?.events[0]?.startDate} - ${props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]?.agenda[0]?.events[0]?.eventEndDate}`
          : `${props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]?.agenda[0]?.events[0]?.startDate} - ${props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]?.agenda[0]?.events[0]?.endDate}`
      }
      slug={
        props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]
          ?.agenda[0]?.events[0]?.slug
      }
      registrationUrl={
        props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]
          ?.agenda[0]?.events[0]?.registrationUrl
      }
      headerbtn={
        props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]
          ?.agenda[0]?.events[0]?.registrationCtaText
      }
      bannerImageLeft={
        props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]
          ?.agenda[0]?.events[0]?.bannerImageLeft
      }
      bannerImageLeftMobile={
        props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]
          ?.agenda[0]?.events[0]?.bannerImageLeftMobile
      }
      bannerImageRight={
        props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]
          ?.agenda[0]?.events[0]?.bannerImageRight
      }
      bannerImageRightMobile={
        props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]
          ?.agenda[0]?.events[0]?.bannerImageRightMobile
      }
      transparentHeader={
        props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]
          ?.agenda[0]?.events[0]?.transparentHeader
      }
      scroll={scrollPosition}
    >
      <Head
        title={
          props?.data?.contentfulSessionDetails?.schedule_details[0]
            ?.schedule[0]?.agenda[0]?.events[0]?.seoMetadata?.title
        }
        description={
          props?.data?.contentfulSessionDetails?.schedule_details[0]
            ?.schedule[0]?.agenda[0]?.events[0]?.seoMetadata?.description
            ?.description
        }
      />
      <div className="agenda_ses_body">
        <div className="agenda_ses_back">
          <Link
            to={`/events/${props?.data?.contentfulSessionDetails?.schedule_details[0]?.schedule[0]?.agenda[0]?.events[0]?.slug}/agenda`}
          >
            <span>&lt;</span> &nbsp;&nbsp;&nbsp;&nbsp;Agenda
          </Link>
        </div>

        <div className="agenda_ses_title">
          {props?.data?.contentfulSessionDetails?.title}
        </div>
        <div className="agenda_ses_time">
          Date :{" "}
          <span>
            {
              props?.data?.contentfulSessionDetails?.schedule_details[0]
                ?.schedule[0]?.date
            }
          </span>
        </div>
        <div className="agenda_ses_time">
          Time : <span>{props?.data?.contentfulSessionDetails?.timing}</span>
        </div>
        <div className="agenda_ses_p">
          <p style={{ whiteSpace: "pre-wrap" }}>
            {props?.data?.contentfulSessionDetails?.details?.details}
          </p>
        </div>

        {props?.data?.contentfulSessionDetails?.speakers && (
          <>
            <div className="agenda_ses_h2">Speakers</div>
            <div className="agenda_ses_speakerlist_body">
              {props?.data?.contentfulSessionDetails?.speakers?.map(
                (item, index) => {
                  return (
                    <div className="agenda_ses_speakerlist_row" key={index}>
                      <div className="agenda_ses_speaker_photo">
                        <img
                          src={
                            item?.image?.file?.url
                              ? item?.image?.file?.url
                              : speakerimg
                          }
                          alt="speakerimage"
                        />
                      </div>
                      <div className="agenda_ses_speaker_data">
                        <div className="agenda_ses_speaker_name">
                          {item?.name}
                        </div>
                        <div className="agenda_ses_speaker_info">
                          {item?.designation && <>{item?.designation},</>}{" "}
                          {item?.organisation}
                        </div>
                      </div>
                    </div>
                  )
                }
              )}
            </div>
          </>
        )}
      </div>
    </PageLayout>
  )
}
export default AgendaSession
